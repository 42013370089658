export default [
  { path: 'mpb/infrastructure/:id?/:section?', component: () => import('./pages/Infrastructure.vue') },
  { path: 'mpb/monitoring/:id?/:section?', component: () => import('./pages/Monitoring.vue') },
  { path: 'mpb/vpn/:id?/:section?', component: () => import('./pages/Vpn.vue') },
  { path: 'mpb/restplayground', component: () => import('./pages/RestPlayground.vue') },
  { path: 'mpb/streamingmedia/:tab?/:mediaType?/:id?/:section?', component: () => import('./pages/StreamingMedia.vue') },
  { path: 'mpb/ecommerce/:tab?/:id?', component: () => import('./pages/Ecommerce.vue') },
  { path: 'mpb/poolrestrictions', component: () => import('./pages/PoolRestrictions.vue') },
  { path: 'mpb/clickcoins', component: () => import('./pages/ClickCoins.vue') }
]
